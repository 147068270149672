<template>
<span>

    <v-form ref="form" v-model="valid" lazy-validation autocomplete="off">
        <v-container fluid>
            <v-row>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <select2 v-model="storegiftlist.user_id" :options="usuarios" :settings="{width: '100%', placeholder: 'Selecione um Usuário...'}"></select2>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <select2 v-model="storegiftlist.store_gift_list_category_id" :options="tipo_lista" :settings="{width: '100%', placeholder: 'Selecione um Tipo...'}"></select2>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_nvo_nome" :rules="rules.stor_gf_nvo_nomeRules" label="Nome do Noivo" outlined required></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_nvo_pai" label="Nome Pai do Noivo" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_nvo_mae" label="Nome Mãe do Noivo" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_nva_nome" :rules="rules.stor_gf_nva_nomeRules" label="Nome da Noiva" outlined required></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_nva_pai" label="Nome do Pai da Noiva" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_nva_mae" label="Nome da Mãe da Noiva" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_data_casamento" :rules="rules.dataCasamentoRules" label="Data do Casamento" v-mask="'##/##/####'" outlined required></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_nvo_email" :rules="rules.stor_gf_nvo_emailRules" label="Email" outlined required></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <p class="title teal--text lighten-1">Dados da Igreja</p>
                    <v-divider horizontal></v-divider>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_igreja" label="Nome da Igreja" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_end_igreja" label="Endereço" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_bairro_igreja" label="Bairro" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_compl_igreja" label="Complemento" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <p class="title teal--text lighten-1">Dados da Festa</p>
                    <v-divider horizontal></v-divider>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_local_festa" label="Local" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_end_festa" label="Endereço" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_bairro_festa" label="Bairro" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_compl_festa" label="Complemento" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-textarea v-model="storegiftlist.stor_gf_mais_info_festa" :counter="600" label="Mais Informações" outlined></v-textarea>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <p class="title teal--text lighten-1">Dados da Entrega antes do casamento</p>
                    <v-divider horizontal></v-divider>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_nome_entrega_antes" label="Nome" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_cep_antes" label="CEP" outlined @blur="getEnderecoAntesByCEP(storegiftlist.stor_gf_cep_antes)"></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_endereco_antes" label="Endereço" outlined disabled></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_num_antes" label="Numero" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_bairro_antes" label="Bairro" outlined disabled></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_cidade_antes" label="Cidade" outlined disabled></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_uf_antes" label="Estado" outlined disabled></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_fone_antes" label="Telefone" v-mask="'(##) ####-####'" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_celular_antes" label="Celular" v-mask="'(##) #-####-####'" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <p class="title teal--text lighten-1">Dados da Entrega após o casamento</p>
                    <v-divider horizontal></v-divider>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_nome_entrega_pos" label="Nome" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_cep_pos" label="CEP" outlined @blur="getEnderecoPosByCEP(storegiftlist.stor_gf_cep_pos)"></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_endereco_pos" label="Endereço" outlined disabled></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_num_pos" label="Numero" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_bairro_pos" label="Bairro" outlined disabled></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_cidade_pos" label="Cidade" outlined disabled></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_uf_pos" label="Estado" outlined disabled></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_fone_pos" label="Telefone" v-mask="'(##) ####-####'" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
                    <v-text-field v-model="storegiftlist.stor_gf_celular_pos" label="Celular" v-mask="'(##) #-####-####'" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-switch color="lime" v-model="storegiftlist.stor_gf_status" label="Status"></v-switch>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">

                    <v-layout wrap column>

                        <v-flex d-flex v-if="preview || storegiftlist.stor_gf_imagem" justify-center>
                            <v-card>
                                <v-img v-if="preview" class="img-preview" :src="preview">
                                </v-img>

                                <v-img v-if="storegiftlist.stor_gf_imagem && !preview" class="img-preview" :src="`${base_url}upload/store_gift_list/${storegiftlist.stor_gf_imagem}`">
                                </v-img>

                                <v-card-actions class="justify-center">
                                    <v-btn icon>
                                        <v-icon @click="$refs.file.click()">cloud_upload</v-icon>
                                    </v-btn>
                                    <v-btn icon>
                                        <v-icon @click.prevent="removePreview">highlight_off</v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-flex>

                        <v-flex d-flex justify-center>
                            <input type="file" ref="file" style="display:none;" id="files" @change="onFileChange" />
                            <v-btn v-if="!preview && !storegiftlist.stor_gf_imagem" color="blue-grey" class="btn-upload white--text" @click="$refs.file.click()">
                                Adicionar Imagem
                                <v-icon right dark>cloud_upload</v-icon>
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-center">
                    <v-btn class="btn-salvar" :disabled="!valid" color="success" @click="onSubmit">
                        <v-icon class="mdi mdi-check mr-2"></v-icon>{{btnLabel}}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-form>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../../config/configs'
import cep from "cep-promise";
import Select2 from 'v-select2-component'

export default {
    name: "FormStoreGiftListComponent",
    props: {
        storegiftlist: {
            require: false,
            type: Object | Array,
            default: () => {
                return {
                    stor_gf_id: '',
                    user_id: '',
                    store_gift_list_category_id: '',
                    stor_gf_nvo_email: '',
                    stor_gf_nvo_nome: '',
                    stor_gf_nvo_pai: '',
                    stor_gf_nvo_mae: '',
                    stor_gf_nva_nome: '',
                    stor_gf_nva_pai: '',
                    stor_gf_nva_mae: '',
                    prod_meta_descricao: '',
                    stor_gf_mais_info_festa: '',
                    stor_gf_data_casamento: '',
                    stor_gf_igreja: '',
                    stor_gf_end_igreja: '',
                    stor_gf_bairro_igreja: '',
                    stor_gf_compl_igreja: '',
                    stor_gf_local_festa: '',
                    stor_gf_end_festa: '',
                    stor_gf_bairro_festa: '',
                    stor_gf_compl_festa: '',
                    stor_gf_mesmo_end_cad: 0,
                    stor_gf_nome_entrega_antes: '',
                    stor_gf_endereco_antes: '',
                    stor_gf_num_antes: '',
                    stor_gf_cep_antes: '',
                    stor_gf_bairro_antes: '',
                    stor_gf_cidade_antes: '',
                    stor_gf_uf_antes: '',
                    stor_gf_fone_antes: '',
                    stor_gf_celular_antes: '',
                    stor_gf_mesmo_end_antes: 0,
                    stor_gf_nome_entrega_pos: '',
                    stor_gf_endereco_pos: '',
                    stor_gf_num_pos: '',
                    stor_gf_cep_pos: '',
                    stor_gf_bairro_pos: '',
                    stor_gf_cidade_pos: '',
                    stor_gf_uf_pos: '',
                    stor_gf_fone_pos: '',
                    stor_gf_celular_pos: '',
                    stor_gf_status: true,
                    stor_gf_finalizada: false,

                }
            }
        },
        update: {
            require: false,
            type: Boolean,
            default: false
        },
        btnLabel: {
            require: false,
            type: String,
            default: 'Salvar'
        },
        require: true,
    },
    created() {
        this.$store.dispatch('loadUsersAll');
        this.$store.dispatch('loadStoreGiftListCategoriesAll');
    },
    computed: {
        usuarios() {
            return this.$store.state.users.all.data
        },
        tipo_lista() {
            return this.$store.state.storegiftlistcategories.all.data
        },
    },
    watch: {

    },

    data() {
        return {
            valid: true,
            rules: {
                stor_gf_nvo_nomeRules: [
                    v => !!v || 'Campo Nome do Noivo é obrigatório',
                ],
                stor_gf_nva_nomeRules: [
                    v => !!v || 'Campo Nome da Noiva é obrigatório',
                ],
                dataCasamentoRules: [
                    v => !!v || 'Campo Data do Casamento é obrigatório',
                ],
                stor_gf_nvo_emailRules: [
                    v => !!v || 'Campo E-Mail é obrigatório',
                    v => /.+@.+/.test(v) || 'E-mail precisa ser válido'
                ],
            },
            upload: null,
            fileName: 'Selecione uma foto',
            preview: null,
            base_url: URL_BASE
        }
    },
    methods: {
        log(msg) {
            console.log(msg)
        },
        onSubmit() {

            const action = this.update ? 'updateStoreGiftList' : 'storeStoreGiftList';
            const msg = this.update ? 'Dados atualizados com sucesso' : 'Cadastro efetuado com sucesso';

            const formData = new FormData()

            if (this.upload !== null) {
                formData.append('stor_gf_imagem', this.upload)
            }

            formData.append('stor_gf_id', this.storegiftlist.stor_gf_id)
            formData.append('user_id', this.storegiftlist.user_id)
            formData.append('store_gift_list_category_id', this.storegiftlist.store_gift_list_category_id)
            formData.append('stor_gf_nvo_email', this.storegiftlist.stor_gf_nvo_email)
            formData.append('stor_gf_nvo_nome', this.storegiftlist.stor_gf_nvo_nome)
            formData.append('stor_gf_nvo_nome_slug', this.storegiftlist.stor_gf_nvo_nome_slug)
            formData.append('stor_gf_nvo_pai', this.storegiftlist.stor_gf_nvo_pai)
            formData.append('stor_gf_nvo_mae', this.storegiftlist.stor_gf_nvo_mae)
            formData.append('stor_gf_nva_nome', this.storegiftlist.stor_gf_nva_nome)
            formData.append('stor_gf_nva_nome_slug', this.storegiftlist.stor_gf_nva_nome_slug)
            formData.append('stor_gf_nva_pai', this.storegiftlist.stor_gf_nva_pai)
            formData.append('stor_gf_nva_mae', this.storegiftlist.stor_gf_nva_mae)
            formData.append('stor_gf_data_casamento', this.storegiftlist.stor_gf_data_casamento)
            formData.append('stor_gf_igreja', this.storegiftlist.stor_gf_igreja)
            formData.append('stor_gf_end_igreja', this.storegiftlist.stor_gf_end_igreja)
            formData.append('stor_gf_bairro_igreja', this.storegiftlist.stor_gf_bairro_igreja)
            formData.append('stor_gf_compl_igreja', this.storegiftlist.stor_gf_compl_igreja)
            formData.append('stor_gf_local_festa', this.storegiftlist.stor_gf_local_festa)
            formData.append('stor_gf_end_festa', this.storegiftlist.stor_gf_end_festa)
            formData.append('stor_gf_bairro_festa', this.storegiftlist.stor_gf_bairro_festa)
            formData.append('stor_gf_compl_festa', this.storegiftlist.stor_gf_compl_festa)
            formData.append('stor_gf_mais_info_festa', this.storegiftlist.stor_gf_mais_info_festa)
            formData.append('stor_gf_rec_pres_quando', this.storegiftlist.stor_gf_rec_pres_quando)
            formData.append('stor_gf_mesmo_end_cad', this.storegiftlist.stor_gf_mesmo_end_cad)

            formData.append('stor_gf_nome_entrega_antes', this.storegiftlist.stor_gf_nome_entrega_antes)
            formData.append('stor_gf_endereco_antes', this.storegiftlist.stor_gf_endereco_antes)
            formData.append('stor_gf_num_antes', this.storegiftlist.stor_gf_num_antes)
            formData.append('stor_gf_cep_antes', this.storegiftlist.stor_gf_cep_antes)
            formData.append('stor_gf_bairro_antes', this.storegiftlist.stor_gf_bairro_antes)
            formData.append('stor_gf_cidade_antes', this.storegiftlist.stor_gf_cidade_antes)
            formData.append('stor_gf_uf_antes', this.storegiftlist.stor_gf_uf_antes)
            formData.append('stor_gf_fone_antes', this.storegiftlist.stor_gf_fone_antes)
            formData.append('stor_gf_celular_antes', this.storegiftlist.stor_gf_celular_antes)
            formData.append('stor_gf_mesmo_end_antes', this.storegiftlist.stor_gf_mesmo_end_antes)

            formData.append('stor_gf_nome_entrega_pos', this.storegiftlist.stor_gf_nome_entrega_pos)
            formData.append('stor_gf_endereco_pos', this.storegiftlist.stor_gf_endereco_pos)
            formData.append('stor_gf_num_pos', this.storegiftlist.stor_gf_num_pos)
            formData.append('stor_gf_cep_pos', this.storegiftlist.stor_gf_cep_pos)
            formData.append('stor_gf_bairro_pos', this.storegiftlist.stor_gf_bairro_pos)
            formData.append('stor_gf_cidade_pos', this.storegiftlist.stor_gf_cidade_pos)
            formData.append('stor_gf_uf_pos', this.storegiftlist.stor_gf_uf_pos)
            formData.append('stor_gf_fone_pos', this.storegiftlist.stor_gf_fone_pos)
            formData.append('stor_gf_celular_pos', this.storegiftlist.stor_gf_celular_pos)

            formData.append('stor_gf_status', this.storegiftlist.stor_gf_status)
            formData.append('stor_gf_imagem', this.storegiftlist.stor_gf_imagem)
            formData.append('stor_gf_finalizada', this.storegiftlist.stor_gf_finalizada)

            this.$store.dispatch(action, formData)
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: msg,
                        showConfirmButton: true,
                        timer: 6000
                    });
                    this.$router.push({
                        name: 'store-gift-lists'
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Erro',
                        text: 'Algo deu errado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                    this.errors = error.response.data.errors
                })

        },
        validate() {
            if (this.$refs.form.validate()) {
                this.snackbar = true
            }
        },
        getEnderecoAntesByCEP(stor_gf_cep_antes) {
            let c = stor_gf_cep_antes.replace(/\D/g, '');
            if (c !== "") {

                let validacep = /^[0-9]{8}$/;

                if (validacep.test(c)) {
                    cep(c)
                        .then(response => {

                            this.storegiftlist.stor_gf_endereco_antes = response.street;
                            this.storegiftlist.stor_gf_bairro_antes = response.neighborhood;
                            this.storegiftlist.stor_gf_cidade_antes = response.city;
                            this.storegiftlist.stor_gf_uf_antes = response.state;

                        })
                        .catch(error => {
                            console.log(error)
                        })
                } else {
                    this.$swal.fire({
                        position: 'center',
                        icon: 'warning',
                        title: 'Formato do CEP inválido.',
                        showConfirmButton: true,
                        timer: 3000
                    });
                }

            } else {
                this.$swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: 'O CEP é requerido.',
                    showConfirmButton: true,
                    timer: 3000
                });
            }

        },
        onFileChange(e) {
            const files = e.target.files || e.dataTransfer.files

            if (!files.length) {
                return
            }

            this.upload = files[0]
            this.fileName = this.upload.name

            this.previewImage(files[0])
        },
        previewImage(file) {
            const reader = new FileReader()
            reader.onload = (e) => {
                this.preview = e.target.result
            }
            reader.readAsDataURL(file)
        },
        removePreview() {
            this.preview = null
            this.upload = null
        },
        getEnderecoPosByCEP(stor_gf_cep_pos) {
            let c = stor_gf_cep_pos.replace(/\D/g, '');
            if (c !== "") {

                let validacep = /^[0-9]{8}$/;

                if (validacep.test(c)) {
                    cep(c)
                        .then(response => {

                            this.storegiftlist.stor_gf_endereco_pos = response.street;
                            this.storegiftlist.stor_gf_bairro_pos = response.neighborhood;
                            this.storegiftlist.stor_gf_cidade_pos = response.city;
                            this.storegiftlist.stor_gf_uf_pos = response.state;

                        })
                        .catch(error => {
                            console.log(error)
                        })
                } else {
                    this.$swal.fire({
                        position: 'center',
                        icon: 'warning',
                        title: 'Formato do CEP inválido.',
                        showConfirmButton: true,
                        timer: 3000
                    });
                }

            } else {
                this.$swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: 'O CEP é requerido.',
                    showConfirmButton: true,
                    timer: 3000
                });
            }

        },
    },
    components: {
        select2: Select2
    },
};
</script>

<style scoped>
.theme--dark.v-sheet {
    background-color: #303030;
    border-color: #303030;
    color: #fff;
    box-shadow: none;
}

.btn-salvar {
    margin: 0;
}

.form-control {
    height: 4.5rem;
}
</style>
