<template>
<span>
    <v-layout mb-4>
        <v-flex xs10 ml-3>
            <h2 class="lime--text lighten-1 pt-3">Gerenciar Lista de Casamento - Edição</h2>
        </v-flex>
        <v-flex xs2 mr-3 text-right>
            <v-btn color="blue-grey" class="black--text" :to="{name: 'store-gift-lists'}">
                <v-icon dark>keyboard_arrow_left</v-icon>
                Voltar
            </v-btn>
        </v-flex>
    </v-layout>

    <form-store-gift-list :storegiftlist="storegiftlist" :update="true"></form-store-gift-list>
</span>
</template>

<script>
import FormStoreGiftListComponent from "./partial/FormStoreGiftListComponent";

export default {
    name: "EditStoreGiftListComponent",
    created() {
        this.loadStoreGiftList()
    },
    props: {
        stor_gf_id: {
            require: true
        }
    },
    data() {
        return {
            storegiftlist: {},
            langs: true
        }
    },
    methods: {
        loadStoreGiftList() {
            this.$store.dispatch('loadStoreGiftList', this.stor_gf_id)
                .then(response => {
                    this.storegiftlist = response
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        type: "error",
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
    },
    components: {
        formStoreGiftList: FormStoreGiftListComponent
    }
};
</script>

<style scoped>

</style>
